<template>
  <div class="w-full">
    <table
      cellspacing="0"
      class="w-full pdf_table overflow-hidden"
      style="font-family: Roboto; font-size: 6px"
    >
      <tr style="background: #6cb9f4">
        <th colspan="4">
          <p class="py-1.5 relative">
            <b>АО УЗБЕКИСТОН ТЕМИР ЙУЛЛАРИ</b>
            <img
              class="absolute right-5 top-1/2 h-5/6 transform -translate-y-1/2"
              src="@/assets/img/logopdf.jpg"
              alt=""
            />
          </p>
        </th>
      </tr>
      <tr style="background: #dddddd; color: red">
        <th colspan="3" class="w-4/5 border-r border-black">
          <div class="flex flex-wrap justify-center -mx-3">
            <p class="px-3">
              <b
                >{{ corrective_type.name === 'BASICA' ? 'ЕЖЕДНЕВНЫЙ ВИЗУАЛЬНЫЙ ОСМОТР' : 'ВИД ТЕХ.ОБСЛ' }} - {{ corrective_type.name }}</b
              >
            </p>
            <p class="px-3">
              <b
                >№ ПОЕЗДА: {{ inspection.train.name }}</b
              >
            </p>
          </div>
        </th>
        <th style="width: 13%">
          <b
            >Дата создания <br />
            {{ inspection.created_time.split("T").shift() }}</b
          >
        </th>
      </tr>
      <tr style="background: #d9dbf0; color: #414bb2">
        <th colspan="3" class="w-4/5 border-r border-black">
          <p class="py-1.5">
            <b>{{ inspection.sample.name }}</b>
          </p>
        </th>
        <th style="width: 13%">
          <b>Страница: 1 из {{ pages }}</b>
        </th>
      </tr>
      <tr>
        <th class="border-r border-black" style="width: 10%">
          <p class="flex flex-col">
            Код п.т. <br />
            <b class="self-start" v-for="code in inspection.code" :key="code">{{ code }}<br /></b
            >
          </p>
        </th>
        <th class="border-r border-black">
          <p>
            № РЕГИТСТРА -
            <b
              >{{ inspection.number }}</b
            >
          </p>
        </th>
        <th class="w-1/4 border-r border-black">
          тип тех обслуживания:
          <b
            >{{
              inspection.sample.type == 10
                ? "PREVENTIVE"
                : inspection.sample.type == 9
                ? "CORRECTIVE"
                : "VANDALISM"
            }}</b
          >
        </th>
        <th style="width: 15%">
          NTM: <br /> <b v-for="instruction in sample.instruction" :key="instruction.id">{{ instruction.name }}<br /></b>
        </th>
      </tr>
      <tr style="background: #c6c6c6">
        <td colspan="4" class="text-center py-1">
          <b>ВЫПОЛНЯЕМЫЕ ОПЕРАЦИИ</b>
        </td>
      </tr>
      <tr>
        <td class="text-center border-r border-black">№</td>
        <td class="text-center border-r border-black">
          <p class="py-1">ОПИСАНИЕ РАБОТ</p>
        </td>
        <td class="text-center border-r border-black">Место нахожд</td>
        <td class="text-center">Подпись исп. / Дата</td>
      </tr>
      <tr ref="tableRow" v-for="(task_result, index) in task_results" :key="index">
        <td class="text-center border-r border-black">{{ index + 1 }}</td>
        <td class="border-r border-black">
          <p class="p-1">
            <b>{{ task_result.task.name }}</b> <br />
            <pre class="my-1" style="white-space: pre-line; font-family: inherit;">
              <i>{{ task_result.task.description }}</i>
            </pre>
          </p>
        </td>
        <td colspan="2" class="p-0">
          <table :style="{height: rows.length ? rows[index] + 'px' : 'auto'}" width="100%">
            <tr class="w-full" v-for="location in task_result.task.task_location" :key="location.id">
              <td style="width: 62.5%;" class="text-center border-r border-black">
                  {{ location.name }}
              </td>
              <td style="width: 37.5%;" class="text-center">
                <tr class="w-full flex justify-center items-center text-center">
                    <div
                      class="relative flex justify-center items-center overflow-hidden"
                      style="min-height: 30px"
                      v-if="location.is_signed"
                    >
                      <p class="text-center relative z-10">
                        {{ location.worker.full_name }}
                        <br />
                        {{ location.signed_time.split("T").shift() }} /
                        {{
                          location.signed_time
                            .split("T")
                            .pop()
                            .split(".")
                            .shift()
                            .slice(0, 5)
                        }}
                      </p>
                      <img
                        class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
                        style="max-width: 80%; max-height: 80%;"
                        src="@/assets/img/logo2.png"
                        alt=""
                      />
                    </div>
                    <div v-else>
                      <div
                      class="border-2 border-red-500 flex justify-center items-center w-max mx-auto p-1 text-red-500 font-medium"
                      >
                      не подписан
                    </div>
                  </div>
                </tr>
              </td>
            </tr>
        </table>
        </td>
      </tr>
      <tr style="background: #fde99f">
        <td colspan="4" class="text-center py-1">
          <b v-if="corrective_type.name !== 'BASICA'">ВЫПОЛНЕННЫЕ ОПЕРАЦИИ НЕ ВХОДЯЩИЙ В ПЛАН ТЕХ.ОБСЛУЖИВАНИЯ</b>
          <b v-else>КОМЕНТАРИИ ИЛИ ВЫПОЛНЕНЫЙ РЕМОНТ ПО ЗАМЕЧАНИЯМ</b>
        </td>
      </tr>
      <tr>
        <td class="text-center border-r border-black">{{ corrective_type.name !== "BASICA" ? "№ АВАРИИ" : "Место нахожд"}}</td>
        <td class="text-center border-r border-black" v-if="corrective_type.name === 'BASICA'">ЗАМЕЧАНИЯ</td>
        <td :colspan="corrective_type.name === 'BASICA' ? 1 : 2" class="text-center border-r border-black">
          <p class="py-1">ВЫПОЛНЕННЫЙ РЕМОНТ</p>
        </td>
        <td class="text-center">Подпись исп. / Дата</td>
      </tr>
      <tr v-for="(repair_list, index) in repairs_list" :key="repair_list.id">
        <td v-if="corrective_type.name !== 'BASICA'" class="text-center border-r border-black">{{ index + 1 }}</td>
        <td v-else class="text-center border-r border-black">{{ repair_list.carriage.name }}</td>
        <td :colspan="corrective_type.name === 'BASICA' ? 1 : 2" class="border-r border-black">
          <p class="p-1">
            <b v-if="corrective_type.name !== 'BASICA'">Код аварии - {{ repair_list.crash_code }} <br /></b>
            <i>{{ repair_list.comment }}</i>
          </p>
        </td>
        <td v-if="corrective_type.name === 'BASICA'" class="border-r border-black text-center">{{ checkStatus(repair_list.status) }}</td>
        <td>
          <div
            class="relative flex justify-center items-center overflow-hidden"
            style="min-height: 30px"
          >
            <p class="text-center relative z-10">
              <span> {{ repair_list.author.full_name }} <br /></span>
              {{ repair_list.created_time.split("T").shift() }} /
              {{
                repair_list.created_time
                  .split("T")
                  .pop()
                  .split(".")
                  .shift()
                  .slice(0, 5)
              }}
            </p>
            <img
              class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
              style="max-width: 80%; max-height: 80%;"
              src="@/assets/img/logo2.png"
              alt=""
            />
          </div>
        </td>
      </tr>
      <tr v-if="!repairs_list.length">
        <td colspan="4">
          <p class="my-3 text-center text-red-500 font-bold">
            НЕТ ВЫПОЛНЕННЫХ РЕМОНТОВ
          </p>
        </td>
      </tr>

      <tr style="background: #fde99f">
        <td colspan="4" class="text-center py-1">
          <b>КОММЕНТАРИИ ПО ТЕХ ОБСЛУЖИВАНИЮ</b>
        </td>
      </tr>
      <tr>
        <td class="text-center border-r border-black">№</td>
        <td colspan="2" class="text-center border-r border-black">
          <p class="py-1">КОММЕНТАРИЙ</p>
        </td>
        <td class="text-center">Подпись исп. / Дата</td>
      </tr>
      <tr v-for="(comment, index) in comments" :key="comment.id">
        <td class="text-center border-r border-black">{{ index + 1 }}</td>
        <td colspan="2" class="border-r border-black">
          <p class="p-1">
            <i>{{ comment.text }}</i>
          </p>
        </td>
        <td>
          <div
            class="relative flex justify-center items-center overflow-hidden"
            style="min-height: 30px"
          >
            <p class="text-center relative z-10">
              {{ comment.user.full_name }}
              <br />
              {{ comment.created_time.split("T").shift() }} /
              {{
                comment.created_time
                  .split("T")
                  .pop()
                  .split(".")
                  .shift()
                  .slice(0, 5)
              }}
            </p>
            <img
              class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
              style="max-width: 80%; max-height: 80%;"
              src="@/assets/img/logo2.png"
              alt=""
            />
          </div>
        </td>
      </tr>
      <tr v-if="!comments.length">
        <td colspan="4">
          <p class="my-3 text-center text-red-500 font-bold">
            НЕТ КОММЕНТАРИЕВ
          </p>
        </td>
      </tr>

      <tr
        v-if="inspection.is_closed || inspection.is_signed"
        style="background: #fde99f"
      >
        <td colspan="4" class="text-center py-1">
          <b>ЗАВЕРШЕНИЕ ТЕХНИЧЕСКОГО ОБСЛУЖИВАНИЯ</b>
        </td>
      </tr>
      <tr v-if="inspection.is_closed || inspection.is_signed">
        <td class="text-center border-r border-black">№</td>
        <td colspan="2" class="text-center border-r border-black">
          <p class="py-1">ОТВЕТСТВЕННЫЕ ЛИЦА</p>
        </td>
        <td class="text-center">Подпись исп. / Дата</td>
      </tr>
      <tr v-if="inspection.is_closed">
        <td class="text-center border-r border-black">1</td>
        <td colspan="2" class="text-center border-r border-black">
          <p>
            <b>{{ inspection.responsible_person.full_name }}</b
            ><br /><i>НАЧАЛЬНИК СМЕНЫ</i>
          </p>
        </td>
        <td>
          <div
            class="relative flex justify-center items-center overflow-hidden"
            style="min-height: 30px"
          >
            <p class="text-center relative z-10">
              {{ inspection.responsible_person.full_name }}
              <br />
              {{ inspection.is_closed_time.split("T").shift() }} /
              {{
                inspection.is_closed_time
                  .split("T")
                  .pop()
                  .split(".")
                  .shift()
                  .slice(0, 5)
              }}
            </p>
            <img
              class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
              style="max-width: 80%; max-height: 80%;"
              src="@/assets/img/logo2.png"
              alt=""
            />
          </div>
        </td>
      </tr>
      <tr v-if="inspection.is_signed">
        <td class="text-center border-r border-black">
          {{ inspection.is_closed ? 2 : 1 }}
        </td>
        <td colspan="2" class="text-center border-r border-black uppercase">
          <p>
            <b>{{ inspection.author.full_name }}</b
            ><br /><i>начальник цеха</i>
          </p>
        </td>
        <td>
          <div
            class="relative flex justify-center items-center overflow-hidden"
            style="min-height: 30px"
          >
            <p class="text-center relative z-10">
              {{ inspection.author.full_name }}
              <br />
              {{ inspection.is_signed_time.split("T").shift() }} /
              {{
                inspection.is_signed_time
                  .split("T")
                  .pop()
                  .split(".")
                  .shift()
                  .slice(0, 5)
              }}
            </p>
            <img
              class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
              style="max-width: 80%; max-height: 80%;"
              src="@/assets/img/logo2.png"
              alt=""
            />
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "PdfFile",
  props: ["inspection", "comments", "repairs_list", "task_results", "corrective_type", "sample", "pages"],
  data() {
    return {
      rows: [],
    }
  },
  mounted (){
    setTimeout(() => {
      const tableRow = this.$refs.tableRow;
      if (tableRow) {
        tableRow.forEach((el) => {
          this.rows.push(el.clientHeight)
        });
      }
    }, 3000);
  },
  methods :{
    checkStatus(status) {
      if (status == 0) {
        return this.$t('REPAIRING');
      } else if (status == 1) {
        return this.$t('DONE');
      } else if (status == 2) {
        return this.$t('NEED_SPARE');
      } else if (status == 3) {
        return this.$t('POSTPONED');
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
