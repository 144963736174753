<template>
  <div>
    <div class="flex items-center mb-4 justify-between">
      <h1 class="font-medium text-3xl">{{ $t("inspections") }}</h1>
      <div class="flex flex-1 mx-2" v-if="inspection">
        <p
          class="py-1 px-3 rounded"
          :class="inspection.is_signed ? 'bg-green-300' : 'bg-red-400'"
        >
          {{ $t("status") }}:
          {{ inspection.is_signed ? $t("finished") : $t("not_finished") }}
        </p>
      </div>
      <div class="flex-1"></div>
      <div class="w-10" v-if="inspection.is_signed">
        <img
          class="w-full h-full object-contain"
          src="@/assets/img/logo.png"
          alt=""
        />
      </div>
      <el-popconfirm
        :confirm-button-text="$t('yes')"
        :cancel-button-text="$t('no_thanks')"
        icon="el-icon-info"
        icon-color="red"
        :title="$t('task_done')"
        @confirm="InspectionSigned"
        v-if="!inspection.is_signed && role == 100"
      >
        <el-button type="success" slot="reference" size="mini">
          <div class="flex items-center">
            <icon
              icon="done"
              class="mr-1 font-normal"
              style="width: 12px"
            ></icon>
            {{ $t("completion") }}
          </div>
        </el-button>
      </el-popconfirm>
      <button
        @click="printPdf"
        v-if="printBtn"
        class="ml-3 flex justify-center items-center px-4 py-2 text-red-500 rounded bg-white border border-red-500"
      >
        <icon style="width: 16px; height: 16px; margin-right: 4px" icon="pdf" />
        {{ $t("download") }}
      </button>
    </div>
    <div
      class="bg-white absolute"
      style="width: 435px; z-index: -99999"
      ref="pdf"
    >
      <PdfFile
        :inspection="inspection"
        :comments="comments"
        :repairs_list="repairs_list"
        :task_results="task_results"
        :corrective_type="corrective_type"
        :sample="sample"
        :pages="pdfPages"
      />
    </div>
    <div class="bg-white border rounded-lg shadow-sm p-3">
      <div class="flex flex-wrap -m-2" v-if="inspection">
        <div class="p-2 w-1/4 3xl:w-1/6">
          <div
            class="px-3 py-2 h-full rounded bg-gradient-to-br from-indigo-400 to-purple-300"
          >
            <p class="">{{ $t("train_name") }}</p>
            <p class="text-lg font-medium">{{ inspection.train.name }}</p>
          </div>
        </div>
        <div class="p-2 w-2/4 3xl:w-1/4">
          <div
            class="px-3 py-2 h-full rounded bg-gradient-to-br from-rose-400 to-purple-300"
          >
            <p class="">{{ $t("register") }}</p>
            <p class="text-lg font-medium">{{ inspection.sample.name }}</p>
          </div>
        </div>
        <div class="p-2 w-1/4 3xl:w-1/6">
          <div
            class="px-3 py-2 h-full rounded bg-gradient-to-br from-green-400 bg-blue-300"
          >
            <p class="">{{ $t("inspection_number") }}</p>
            <p class="text-lg font-medium">{{ inspection.number }}</p>
          </div>
        </div>
        <div class="p-2 w-1/4 3xl:w-1/6">
          <div
            class="px-3 py-2 h-full rounded bg-gradient-to-br from-red-300 to-yellow-200"
          >
            <p class="">{{ $t("Plan_code") }}</p>
            <p v-for="code in inspection.code" :key="code" class="text-lg font-medium">{{ code }}</p>
          </div>
        </div>
        <div class="p-2 w-1/4 3xl:w-1/6">
          <div
            class="px-3 py-2 h-full rounded bg-gradient-to-br from-pink-400 to-orange-300"
          >
            <p class="">{{ $t("inspection_type") }}</p>
            <p class="text-lg font-medium">
              {{
                inspection.sample.type == 10
                  ? "PREVENTIVE"
                  : inspection.sample.type == 9
                  ? "CORRECTIVE"
                  : "VANDALISM"
              }}
            </p>
          </div>
        </div>
        <div class="p-2 w-1/4 3xl:2-1/6">
          <div class="px-3 py-2 h-full rounded bg-gradient-to-br from-pink-400 to-orange-300">
              <p>{{ inspection.responsible_person.full_name }}</p>
              <p class="text-lg font-medium">{{ inspection.created_time.split("T").shift() }}
                {{ inspection.created_time
                    .split("T")
                    .pop()
                    .split(".")
                    .shift() 
                }}
              </p>
          </div>
        </div>
      </div>
    </div>
    <div class="p-3 bg-white border rounded-lg shadow-sm border-red-500 mt-4">
      <p class="text-lg font-medium">{{ $t("inspection_comment") }}</p>
      <p>{{ inspection.comment }}</p>
    </div>
    <el-dialog :visible.sync="update_time" width="fit-content" :with-header="false" @close="closeUpdateTime">
      <h1 class="font-bold text-xl text-center text-111E52">{{ updatedTime ? $t('update_time') : $t('update_worker') }}</h1>
      <div class="p-4">
        <el-date-picker
          v-if="updatedTime"
          v-model="updatedTime"
          type="datetime"
          :placeholder="$t('update_time')"
          default-time="12:00:00">
        </el-date-picker>
        <el-form v-else ref="checkWorker" :model="worker" label-position="top">
          <el-form-item prop="selectedWorker" style="margin-bottom: 10px" :label="$t('workers')" :rules="{
            required: true,
            message: $t('please_select'),
            trigger: 'blur',
          }">
            <el-select v-model="worker.selectedWorker" :placeholder="$t('select')" class="w-full">
              <el-option v-for="worker in workers" :key="worker.id" :label="worker.full_name" :value="worker.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="p-4 mt-4 text-center">
        <el-button :loading="loadingTime" @click="updatedTime ? addUpdatedTime() : addUpdatedWorker()" type="success">{{ $t('edit') }}</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="isClosedDialog" width="fit-content" :with-header="false" @close="closeIsClosedDialog">
      <h1 class="font-bold text-xl text-center text-111E52">{{ $t('update_moderator') }}</h1>
      <div class="p-4">
        <el-form ref="checkModerator" :model="moderator" label-position="top">
          <el-form-item prop="selectedModerator" style="margin-bottom: 10px" :label="$t('moderators')" :rules="{
            required: true,
            message: $t('please_select'),
            trigger: 'blur',
          }">
            <el-select v-model="moderator.selectedModerator" :placeholder="$t('select')" class="w-full">
              <el-option v-for="moderator in moderators" :key="moderator.id" :label="moderator.full_name" :value="moderator.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="selectedTime" style="margin-bottom: 10px" :label="$t('time')" :rules="{
            required: true,
            message: $t('please_select'),
            trigger: 'blur',
          }">
            <el-date-picker
              v-model="moderator.selectedTime"
              type="datetime"
              :placeholder="$t('update_time')"
              default-time="12:00:00">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div class="p-4 mt-4 text-center">
        <el-button :loading="loadingIsClosedTime" @click="addUpdatedIsClosed()" type="success">{{ $t('edit') }}</el-button>
      </div>
    </el-dialog>
    <div class="mt-4">
      <div class="-m-2 flex flex-wrap">
        <div class="p-2 w-full 3xl:w-1/2">
          <div
            class="bg-white h-full border rounded-lg shadow-sm p-3"
            v-if="task_results"
          >
            <div
              class="flex justify-between pt-2 pb-3 border-b mb-5 items-center"
            >
              <h1 class="font-medium flex items-center text-2xl relative">
                {{ $t("tasks") }}
                <el-tag
                  size="small"
                  class="ml-2"
                  :type="inspection.is_closed ? 'success' : 'danger'"
                >
                  {{
                    inspection.is_closed ? $t("completed") : $t("not_completed")
                  }}
                </el-tag>
              </h1>
              <div class="w-10" v-if="inspection.is_closed && role != 100 ">
                <img
                  class="w-full h-full object-contain"
                  src="@/assets/img/logo.png"
                  alt=""
                />
              </div>
              <el-popconfirm
                :confirm-button-text="$t('yes')"
                :cancel-button-text="$t('no_thanks')"
                icon="el-icon-info"
                icon-color="red"
                :title="$t(inspection.is_closed ? 'task_canceled':'task_done')"
                @confirm="!inspection.is_closed && role == 100 ? isClosedDialog = true : InspectionClosed()"
                v-if="!inspection.is_closed && role == 10 || inspection.is_closed && role == 100 || inspection.is_signed && role == 100"
              >
                <el-button slot="reference" size="small" :type="inspection.is_closed ? 'danger' : 'success'">
                  {{ inspection.is_closed ? $t("cancel") : $t("done") }}
                </el-button>
              </el-popconfirm>
            </div>
            <div
              v-for="task_result in task_results"
              :key="task_result.id"
              class="border p-4 mb-4 rounded shadow"
            >
              <h2 class="font-medium text-lg mb-2">
                {{ task_result.task.name }}
              </h2>
              <pre class="mb-3" style="white-space: pre-line; font-family: inherit;">
                {{ task_result.task.description }}
              </pre>
              <el-popconfirm
                :confirm-button-text="$t('yes')"
                :cancel-button-text="$t(role == 100 && location.is_signed ? 'update_time' : 'no_thanks')"
                icon="el-icon-info"
                icon-color="red"
                :title="$t(location.is_signed ? 'task_canceled' : 'task_done')"
                class="mr-1"
                v-for="location in task_result.task.task_location" :key="location.id" :label="location.id"
                @confirm="role == 100 && !location.is_signed ? updateLocationWorker(location.task_location_result_id) : locationSigned(location.task_location_result_id, location.is_signed)"
                @cancel="role == 100 && location.is_signed ? updateLocationTime(location.is_signed, location.task_location_result_id, location.signed_time, location.worker.id) : ''"
                >
                  <el-button slot="reference" size="small" :disabled="location.is_signed && role != 100 || role != 100 && role != 9 || !location.is_signed && !inspection.is_signed && role == 100" :type="location.is_signed ? 'success' : 'danger'" :icon="location.is_signed ? 'el-icon-check' : 'el-icon-close'" round >{{ location.name }}
                    {{ location.is_signed ? '(' + location.signed_time.split("T").shift() : '' }} 
                    {{ location.is_signed ? location.signed_time
                            .split("T")
                            .pop()
                            .split(".")
                            .shift()
                            .slice(0, 5) + ')' : ''
                    }}
                  </el-button>
              </el-popconfirm>
            </div>
          </div>
        </div>
        <div class="p-2 w-full 3xl:w-1/2">
          <div class="bg-white border rounded-lg shadow-sm p-3 h-full">
            <div
              class="flex justify-between py-2 pb-3 border-b mb-5 items-center"
            >
              <h1 class="font-medium text-2xl">{{ corrective_type.name === 'BASICA' ? $t("repairs_basica") : $t("repairs") }}</h1>
              <el-button
                v-if="role == 9"
                @click="dialogRepair = true"
                size="medium"
                ><i class="el-icon-plus"></i> {{ corrective_type.name === 'BASICA' ? $t("add_repair_basica") : $t("add_repair") }}</el-button
              >
            </div>
            <div class="overflow-hidden">
              <el-table
                :data="repairs_list"
                v-loading="loading_repair"
                style="width: 100%"
              >
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <div class="p-3 bg-blue-600 bg-opacity-10">
                      <div class="pb-1.5 border-b mb-2" v-if="role == 9">
                        <el-button
                          type="info"
                          @click="RepairStatus('0', props.row.id)"
                          size="mini"
                          >{{ $t("REPAIRING") }}</el-button
                        >
                        <el-button
                          v-if="props.row.status != 1"
                          type="success"
                          @click="RepairStatus('1', props.row.id)"
                          size="mini"
                          >{{ $t("DONE") }}</el-button
                        >
                        <el-button
                          v-if="props.row.status != 1 && props.row.status != 2"
                          type="warning"
                          @click="RepairStatus('2', props.row.id)"
                          size="mini"
                          >{{ $t("NEED_SPARE") }}</el-button
                        >
                        <el-button
                          v-if="props.row.status != 1 && props.row.status != 3"
                          type="primary"
                          @click="RepairStatus('3', props.row.id)"
                          size="mini"
                          >{{ $t("POSTPONED") }}</el-button
                        >
                      </div>
                      <div v-if="props.row.comment">
                        <h4 class="text-lg">{{ corrective_type.name === 'BASICA' ? $t("basica") : $t('comment') }}</h4>
                        <p class="py-2 border-b mb-2">
                          {{ props.row.comment }}
                        </p>
                      </div>
                      <div class="flex items-center justify-between mb-3">
                        <p>{{ $t("spares") }}</p>
                        <el-button
                          v-if="role == 9"
                          size="mini"
                          @click="openSpareAdd(props.row.id)"
                        >
                          + {{ $t("add_spare") }}
                        </el-button>
                      </div>
                      <div
                        class="overflow-hidden spare_table"
                        v-if="props.row.spares.length"
                      >
                        <el-table
                          border
                          :data="props.row.spares"
                          style="width: 100%"
                        >
                          <el-table-column prop="name" :label="$t('name')">
                          </el-table-column>
                          <el-table-column
                            prop="serial_number"
                            :label="$t('serial_number')"
                          >
                          </el-table-column>
                          <el-table-column :label="$t('status')">
                            <template slot-scope="scope">
                              <el-tag
                                type="danger"
                                size="small"
                                v-if="scope.row.status == 11"
                              >
                                {{ $t("ASKED") }}
                              </el-tag>
                              <el-tag
                                type="warning"
                                size="small"
                                v-if="scope.row.status == 22"
                              >
                                {{ $t("PREPARING") }}
                              </el-tag>
                              <el-tag
                                type="success"
                                size="small"
                                v-if="scope.row.status == 33"
                              >
                                {{ $t("COME") }}
                              </el-tag>
                            </template>
                          </el-table-column>
                          <el-table-column
                            :label="$t('actions')"
                            width="120"
                            v-if="role == 9"
                          >
                            <template slot-scope="scope">
                              <el-button
                                size="mini"
                                @click="
                                  openSpareEdit(props.row.id, scope.row.id)
                                "
                              >
                                <i class="el-icon-edit"></i>
                              </el-button>
                              <el-popconfirm
                                :confirm-button-text="$t('yes')"
                                :cancel-button-text="$t('no_thanks')"
                                icon="el-icon-info"
                                icon-color="red"
                                :title="$t('you_want_to_delete')"
                                class="ml-2"
                                @confirm="SpareDelete(scope.row.id)"
                              >
                                <el-button
                                  slot="reference"
                                  icon="el-icon-delete"
                                  size="mini"
                                  type="danger"
                                >
                                </el-button>
                              </el-popconfirm>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="corrective_type.name !== 'BASICA'"
                  align="center"
                  prop="crash_code"
                  :label="$t('crash_code')"
                  min-width="80"
                >
                </el-table-column>
                <el-table-column :label="$t('train')" align="center">
                  <el-table-column
                    :label="$t('name')"
                    min-width="180"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.carriage.name }}
                    </template>
                  </el-table-column>
                  <el-table-column
                  v-if="corrective_type.name !== 'BASICA'"
                    prop="carriage.code"
                    :label="$t('code')"
                    min-width="100"
                  >
                  </el-table-column>
                </el-table-column>
                <el-table-column :label="$t('status')" min-width="160">
                  <template slot-scope="scope">
                    <el-tag
                      size="mini"
                      type="info"
                      v-if="scope.row.status == 0"
                      >{{ $t("REPAIRING") }}</el-tag
                    >
                    <el-tag
                      size="mini"
                      type="success"
                      v-if="scope.row.status == 1"
                      >{{ $t("DONE") }}</el-tag
                    >
                    <el-tag
                      size="mini"
                      type="warning"
                      v-if="scope.row.status == 2"
                      >{{ $t("NEED_SPARE") }}</el-tag
                    >
                    <el-tag size="mini" v-if="scope.row.status == 3">{{
                      $t("POSTPONED")
                    }}</el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('actions')"
                  width="120"
                  v-if="role == 9"
                >
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="openRepairEdit(scope.row.id)"
                    >
                      <i class="el-icon-edit"></i>
                    </el-button>
                    <el-popconfirm
                      :confirm-button-text="$t('yes')"
                      :cancel-button-text="$t('no_thanks')"
                      icon="el-icon-info"
                      icon-color="red"
                      :title="$t('you_want_to_delete')"
                      class="ml-2"
                      @confirm="RepairDelete(scope.row.id)"
                    >
                      <el-button
                        slot="reference"
                        icon="el-icon-delete"
                        size="mini"
                        type="danger"
                      >
                      </el-button>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <el-dialog
          :visible.sync="dialogComment"
          width="600px"
          class="rounded-lg"
          @close="CloseComment"
        >
          <span slot="title" class="text-xl font-medium">
            {{ $t("add_comment") }}
          </span>
          <div>
            <el-form :model="comment" ref="comment_text" label-position="top">
              <el-form-item
                :label="$t('comment')"
                prop="text"
                :rules="{
                  required: true,
                  message: $t('please_input'),
                  trigger: 'change',
                }"
              >
                <el-input v-model="comment.text" type="textarea" :rows="3">
                </el-input>
              </el-form-item>
            </el-form>
            <div class="flex justify-end">
              <el-button v-if="edit_Comment" @click="editComment">{{
                $t("save")
              }}</el-button>
              <el-button v-else @click="SaveComment">{{
                $t("save")
              }}</el-button>
            </div>
          </div>
        </el-dialog>
        <el-dialog
          :visible.sync="dialogRepair"
          width="600px"
          class="rounded-lg"
          @close="CloseRepair"
        >
          <span slot="title" class="text-xl font-medium">
            {{ corrective_type.name === "BASICA" ? $t("add_repair_basica") : $t("add_repair") }}
          </span>
          <div>
            <el-form
              :model="repair"
              :rules="rules"
              ref="repair"
              label-position="top"
            >
              <div class="flex -m-2">
                <el-form-item
                  :label="$t('carriage')"
                  prop="carriage"
                  :rules="{
                    required: true,
                    message: $t('please_select'),
                    trigger: 'blur',
                  }"
                  class="p-2"
                  :class="corrective_type.name !== 'BASICA' ? 'w-1/2' : 'w-full'"
                  style="margin-bottom: 8px"
                >
                  <el-select
                    class="w-full"
                    v-model="repair.carriage"
                    :placeholder="$t('select')"
                  >
                    <el-option
                      v-for="carriage in carriages"
                      :key="carriage.id"
                      :label="carriage.name"
                      :value="carriage.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-if="corrective_type.name !== 'BASICA'"
                  :label="$t('crash_code')"
                  prop="crash_code"
                  class="w-1/2 p-2"
                  style="margin-bottom: 8px"
                >
                  <el-input
                    :placeholder="$t('input')"
                    v-model.number="repair.crash_code"
                  ></el-input>
                </el-form-item>
              </div>
              <el-form-item :label="corrective_type.name !== 'BASICA' ? $t('comment') : $t('basica')">
                <el-input v-model="repair.comment" type="textarea" :rows="3">
                </el-input>
              </el-form-item>
            </el-form>
            <div class="flex justify-end">
              <el-button v-if="editR" @click="EditRepair">{{
                $t("save")
              }}</el-button>
              <el-button v-else @click="SaveRepair">{{ $t("save") }}</el-button>
            </div>
          </div>
        </el-dialog>
        <el-dialog
          :visible.sync="dialogSpare"
          width="400px"
          @close="CloseSpare"
        >
          <span slot="title" class="text-xl font-medium">{{
            $t("add_spare")
          }}</span>
          <div>
            <el-form :model="spare" ref="spare" label-position="top">
              <el-form-item
                :label="$t('name')"
                prop="name"
                :rules="{
                  required: true,
                  message: $t('please_input'),
                  trigger: 'blur',
                }"
                style="margin-bottom: 8px"
              >
                <el-input
                  :placeholder="$t('input')"
                  v-model="spare.name"
                ></el-input>
              </el-form-item>
              <el-form-item
                :label="$t('serial_number')"
                prop="serial_number"
                style="margin-bottom: 8px"
              >
                <el-input
                  :placeholder="$t('input')"
                  v-model="spare.serial_number"
                ></el-input>
              </el-form-item>
              <el-form-item
                :label="$t('status')"
                prop="status"
                :rules="{
                  required: true,
                  message: $t('please_select'),
                  trigger: 'blur',
                }"
              >
                <el-select
                  v-model="spare.status"
                  :placeholder="$t('select')"
                  class="w-full"
                >
                  <el-option
                    v-for="status in spare_status"
                    :key="status.id"
                    :label="status.name"
                    :value="status.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div class="flex justify-end">
              <el-button v-if="editS" @click="EditSpare">{{
                $t("save")
              }}</el-button>
              <el-button v-else @click="SaveSpare">{{ $t("save") }}</el-button>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>

    <div class="bg-white h-full border rounded-lg shadow-sm p-3 mt-4">
      <div class="flex justify-between py-2 pb-3 border-b mb-5 items-center">
        <h1 class="font-medium text-2xl">{{ $t("comment") }}</h1>
        <el-button @click="dialogComment = true" size="medium"
          ><i class="el-icon-plus"></i> {{ $t("add") }}</el-button
        >
      </div>
      <div
        v-for="comment in comments"
        :key="comment.id"
        class="border mb-2 p-3 rounded"
      >
        <div class="flex justify-between items-center">
          <p class="text-gray-600 pb-1 w-max border-b mb-1">
            {{ comment.user.full_name }}
          </p>
          <div class="flex-1"></div>
          <div class="flex items-center" v-if="comment.user.role === role">
            <el-button
              size="mini"
              icon="el-icon-edit"
              @click="openEditComment(comment.id)"
            >
            </el-button>
            <el-popconfirm
              :confirm-button-text="$t('yes')"
              :cancel-button-text="$t('no_thanks')"
              icon="el-icon-info"
              icon-color="red"
              :title="$t('you_want_to_delete')"
              class="ml-2"
              @confirm="CommentDelete(comment.id)"
            >
              <el-button
                slot="reference"
                icon="el-icon-delete"
                size="mini"
                type="danger"
              >
              </el-button>
            </el-popconfirm>
          </div>
          <div class="w-6 ml-2">
            <img class="w-full h-full" src="@/assets/img/logo.png" alt="" />
          </div>
        </div>
        <p>{{ comment.text }}</p>
      </div>
    </div>
    <div class="bg-white border rounded-lg shadow-sm p-3 px-4 mt-4">
      <div class="-m-3 flex flex-wrap" v-if="inspection && sample">
        <div class="p-3">
          <div class="flex flex-wrap -m-2">
            <div class="p-2">
              <p class="font-medium p-1.5 py-0.5 border rounded-sm">
                {{ $t("author") }} :
                <span class="font-normal">{{
                  inspection.author.full_name
                }}</span>
              </p>
            </div>
            <div class="p-2">
              <p class="font-medium p-1.5 py-0.5 border rounded-sm">
                {{ $t("responsible_person") }} :
                <span class="font-normal">{{
                  inspection.responsible_person.full_name
                }}</span>
              </p>
            </div>
            <div class="p-2">
              <p
                class="font-medium p-1.5 py-0.5 border rounded-sm"
              >
                {{ $t("worker") }} :
                <span v-for="worker in inspection.workers" :key="worker.id" class="font-normal border rounded-md px-1 mr-1 bg-blue-50">{{ worker.full_name }}</span>
              </p>
            </div>
            <div class="p-2">
              <p class="font-medium p-1.5 py-0.5 border rounded-sm">
                {{ $t("instruction") }} :
                <span v-for="instruction in sample.instruction" :key="instruction.id" class="font-normal border rounded-md px-1 mr-1 bg-blue-50">{{instruction.name }} </span>
              </p>
            </div>
            <div class="p-2">
              <p class="font-medium p-1.5 py-0.5 border rounded-sm">
                {{ $t("place") }} :
                <span v-for="plc in sample.place" :key="plc.id" class="font-normal border rounded-md px-1 mr-1 bg-blue-50">{{ plc.name }}</span>
              </p>
            </div>
            <div class="p-2">
              <p class="font-medium p-1.5 py-0.5 border rounded-sm">
                {{ $t("sample_name") }}:
                <span class="font-normal">{{ sample.name }}</span>
              </p>
            </div>
            <div class="p-2 flex">
              <p
                class="font-medium mr-3 p-1.5 py-0.5 flex items-center border rounded-sm"
              >
                {{ $t("sample_file") }}:
                <a
                  :href="instruction.file"
                  target="_blank"
                  class="flex items-center text-blue-600 ml-1 border rounded-md px-1 mr-1 bg-blue-50"
                  v-for="instruction in sample.instruction" :key="instruction.id"
                >
                  <icon
                    style="
                      width: 12px;
                      height: 12px;
                      color: red;
                      margin-right: 4px;
                    "
                    icon="file"
                  ></icon>
                  {{ decodeURI(instruction.file.split("/").pop()) }}
                </a>
              </p>
            </div>
          </div>
        </div>
        <div class="p-3"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { jsPDF } from "jspdf";
import PdfFile from "../components/PdfFile.vue";
import "../plugins/jsPdf/Roboto-Bold";
import "../plugins/jsPdf/Roboto-Medium";
import "../plugins/jsPdf/Roboto-normal";
import axios from "axios";
import icon from "../components/icon.vue";
export default {
  components: { icon, PdfFile },
  name: "InspectionDetail",
  data() {
    var checkNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("please_input")));
      }
      if (!Number.isInteger(value)) {
        callback(new Error(this.$t("please_input_digits")));
      } else {
        callback();
      }
    };
    return {
      update_time: false,
      printBtn: false,
      updatedTime: null,
      isClosedDialog: false,
      locationId: null,
      loadingTime: false,
      pdfPages: 0,
      inspection: "",
      task_results: "",
      sample: "",
      loading_inspection: false,
      loading_task: false,
      corrective_type: "",
      loadingIsClosedTime: false,
      moderators: "",
      workers: "",
      worker: {
        selectedWorker: ""
      },
      moderator: {
        selectedModerator: "",
        selectedTime: ""
      },
      comment: {
        text: "",
      },
      comments: [],
      loading_comment: false,
      edit_Comment: false,
      edit_comment_id: "",
      dialogComment: false,
      dialogRepair: false,
      loading_repair: false,
      edit_repair: "",
      editR: false,
      edit_repair_id: "",
      carriages: "",
      repairs_list: [],
      repair: {
        inspection: "",
        carriage: "",
        crash_code: "",
        comment: "",
        status: "",
      },
      rules: {
        crash_code: [{ validator: checkNumber, trigger: "blur" }],
      },

      dialogSpare: false,
      edit_spare: "",
      editS: false,
      edit_spare_id: "",
      spares_list: [],
      spare: {
        repair: "",
        name: "",
        serial_number: "",
        status: "",
      },
      role: localStorage.role,
    };
  },
  watch: {
    '$i18n.locale': function() {
      this.getTaskResults();
    }
  },
  mounted() {
    setTimeout(() => {
      this.printBtn = true;
    }, 3000)
    this.getInspection();
    this.getTaskResults();
    this.getRepairs();
    this.getWorkers();
    this.getModerators();
    this.getComments();
  },
  computed: {
    spare_status() {
      return [
        {
          id: "11",
          name: this.$t("ASKED"),
        },
        {
          id: "22",
          name: this.$t("PREPARING"),
        },
        {
          id: "33",
          name: this.$t("COME"),
        },
      ];
    },
  },
  methods: {
    async printPdf() {
      var doc = new jsPDF("p", "px", "a4");
      doc.setFont("Roboto", "medium");
      
      await doc.html(this.$refs.pdf, {
        retina: true,
        pagesplit: true,
        margin: [5.5, 5.5, 5.5, 5.5],
        callback: function (doc) {
          this.pdfPages = doc.getNumberOfPages();
          // doc.autoPrint();
          // doc.output("dataurlnewwindow");
        }.bind(this),
      });
      await doc.html(this.$refs.pdf, {
        margin: [5.5, 5.5, 5.5, 5.5],
        callback: function (doc) {
          doc.save(this.inspection.train.name + '-' + this.corrective_type.name + '-' + this.inspection.number + '.pdf');
        }.bind(this),
      });
    },
    async getComments() {
      await axios
        .get(`comments/?no_page&inspection=${this.$route.params.id}`)
        .then((response) => {
          this.comments = response.data;
        });
    },

    async getInspection() {
      await axios
        .get(`inspections/${this.$route.params.id}/`)
        .then((response) => {
          this.inspection = response.data;
          this.inspection.code = this.inspection.code.split(" ")
          this.getCarriages(response.data.train.id);
          this.samples(response.data.sample.id);
          response.data.sample.corrective_type ? this.getCorrectiveType(response.data.sample.corrective_type) : ''
        })
        .catch(() => {
          console.log("error")
        });
    },
    async getCarriages(id) {
      await axios.get(`carriages/?no_page&train=${id}`).then((response) => {
        this.carriages = response.data;
      });
    },
    async getCorrectiveType(id) {
      await axios.get(`corrective-types/${id}/`).then((response) => {
        this.corrective_type = response.data;
      });
    },
    async getWorkers() {
      await axios.get(`users/?filter_by=workers&inspection=${this.$route.params.id}`).then((response) => {
        this.workers = response.data.results;
      });
    },
    async getModerators() {
      await axios.get(`users/?filter_by=moderators&inspection=${this.$route.params.id}`).then((response) => {
        this.moderators = response.data.results;
      });
    },
    async getTaskResults() {
      this.task_results = "";
      this.loading_repair = true;
      if(this.$i18n.locale === 'uz') {
        await axios
        .get(`task_results/?no_page&inspection=${this.$route.params.id}`)
        .then((response) => {
          this.loading_repair = false;
          this.task_results = response.data;
        });
      } else {
        const storageToken = localStorage.getItem("token");
          const response = await fetch(`${process.env.VUE_APP_BASE_URL}/ru/api/task_results/?no_page&inspection=${this.$route.params.id}`, {
            method: 'GET',
            headers: {
              'ngrok-skip-browser-warning': "any",
              'Authorization': storageToken ? `Token ${storageToken}` : "",
              'Content-Type': 'application/json'
          }
            });

          if (!response.ok) {
            throw new Error('Network response was not ok.');
          }
          
          this.loading_repair = false;
          this.task_results = await response.json();
      }
    },
    async samples(id) {
      await axios.get(`samples/${id}/?inspection=${this.$route.params.id}`).then((response) => {
        this.sample = response.data;
      });
    },
    async TaskDone(id) {
      await axios.patch(`task_results/${id}/`, { is_signed: true }).then(() => {
        this.getTaskResults();
      });
    },
    async InspectionSigned() {
      await axios
        .patch(`inspections/${this.$route.params.id}/`, { is_signed: true })
        .then(() => {
          this.getInspection();
        });
    },
    async InspectionClosed() {
      await axios
        .patch(`inspections/${this.$route.params.id}/`, { is_closed: this.role == 10 ? true : false })
        .then(() => {
          this.getInspection();
        });
    },
    async locationSigned(id, status) {
      if (!status && this.role == 9 || status && this.role == 100) {
        await axios
          .patch(`location_task_results/${id}/`, { is_signed: !status})
          .then(() => {
            this.getTaskResults();
        });
      } else {
        this.$notify.error({
          title: this.$t("error"),
          message: this.$t("attempting_hacking"),
        });
      }
    },
    updateLocationTime(status, id, time, worker) {
      if (status && this.role == 100) {
        this.update_time = true;
        this.locationId = id;
        this.updatedTime = time;
        this.worker.selectedWorker = worker;
      } else {
        this.$notify.error({
          title: this.$t("error"),
          message: this.$t("attempting_hacking"),
        });
      }
    },
    updateLocationWorker(id) {
      if (this.inspection.is_signed && this.role == 100) {
        this.update_time = true;
        this.locationId = id;
      } else {
        this.$notify.error({
          title: this.$t("error"),
          message: this.$t("attempting_hacking"),
        });
      }
    },
    async addUpdatedTime() {
      this.loadingTime = true;
      if (typeof this.updatedTime == 'object') {
        this.updatedTime.setUTCHours(this.updatedTime.getUTCHours() + 5);
      }
      await axios
        .patch(`location_task_results/${this.locationId}/`, {signed_time: typeof this.updatedTime == 'object' ? this.updatedTime.toISOString() : this.updatedTime, worker: {id: this.worker.selectedWorker}})
        .then(() => {
          this.updatedTime = "";
          this.update_time = false;
          this.loadingTime = false;
          this.worker.selectedWorker = "";
          this.getTaskResults();
        })
        .catch(() => {
          this.update_time = false;
          this.loadingTime = false;
          this.updatedTime = null;
          this.worker.selectedWorker = "";
        });
    },
    addUpdatedWorker() {
      this.$refs.checkWorker.validate((valid) => {
        if(valid) {
          this.loadingTime = true;
          axios
            .patch(`location_task_results/${this.locationId}/`, {is_signed: true, worker: {id: this.worker.selectedWorker}})
            .then(() => {
              this.update_time = false;
              this.loadingTime = false;
              this.updatedTime = null;
              this.worker.selectedWorker = "";
              this.getTaskResults();
            })
            .catch(() => {
              this.update_time = false;
              this.loadingTime = false;
              this.updatedTime = null;
              this.worker.selectedWorker = "";
            });
        }
      })
    },
    addUpdatedIsClosed() {
      this.$refs.checkModerator.validate((valid) => {
        if(valid) {
          this.loadingIsClosedTime = true;
          this.moderator.selectedTime.setUTCHours(this.moderator.selectedTime.getUTCHours() + 5);
          axios
            .patch(`inspections/${this.$route.params.id}/`, { is_closed: true, is_closed_time: this.moderator.selectedTime.toISOString(), responsible_person: this.moderator.selectedModerator })
            .then(() => {
              this.isClosedDialog = false;
              this.moderator.selectedModerator = "";
              this.moderator.selectedTime = "";
              this.loadingIsClosedTime = false;
              this.getInspection();
            });
        }
      })
    },
    closeUpdateTime() {
      this.update_time = false;
      this.updatedTime = null;
      this.worker.selectedWorker = "";
    },
    closeIsClosedDialog() {
      this.isClosedDialog = false;
      this.moderator.selectedModerator = "";
      this.moderator.selectedTime = "";
    },
    async getRepairs() {
      this.loading_repair = true;
      await axios
        .get(`repairs/?no_page&inspection=${this.$route.params.id}`)
        .then((response) => {
          this.repairs_list = response.data;
          this.loading_repair = false;
        })
        .catch(() => {
          this.loading_repair = false;
        });
    },
    SaveRepair() {
      this.repair.inspection = this.$route.params.id;
      this.repair.status = "0";
      if(this.corrective_type.name === 'BASICA') {
        this.repair.crash_code = Number(new Date().getTime().toString().slice(0, 8));
      }
      this.$refs.repair.validate((valid) => {
        if (valid) {
          this.loading_repair = true;
          axios
            .post(`repairs/`, this.repair)
            .then(() => {
              this.loading_repair = false;
              this.dialogRepair = false;
              this.getRepairs();
              this.$notify({
                title: this.$t("success"),
                message: this.$t("data_added"),
                type: "success",
              });
            })
            .catch(() => {
              this.loading_repair = false;
              this.$notify.error({
                title: this.$t("error"),
                message: this.$t("no_data_added"),
              });
            });
        }
      });
    },
    openRepairEdit(id) {
      this.dialogRepair = true;
      this.editR = true;
      this.edit_repair_id = id;
      this.edit_repair = this.repairs_list.find((data) => data.id === id);
      Object.keys(this.repair).forEach((key) => {
        if (typeof this.edit_repair[key] === "object") {
          this.repair[key] = this.edit_repair[key].id;
        } else {
          this.repair[key] = this.edit_repair[key];
        }
      });
    },
    EditRepair() {
      this.$refs.repair.validate((valid) => {
        if (valid) {
          this.loading_repair = true;
          axios
            .patch(`repairs/${this.edit_repair_id}/`, this.repair)
            .then(() => {
              this.loading_repair = false;
              this.dialogRepair = false;
              this.getRepairs();
              this.$notify({
                title: this.$t("success"),
                message: this.$t("data_changed"),
                type: "success",
              });
            })
            .catch(() => {
              this.loading_repair = false;
              this.$notify.error({
                title: this.$t("error"),
                message: this.$t("data_unchanged"),
              });
            });
        }
      });
    },
    RepairStatus(status, repairId) {
      this.loading_repair = true;
      axios
        .patch(`repairs/${repairId}/`, { status: status })
        .then(() => {
          this.loading_repair = false;
          this.getRepairs();
          this.$notify({
            title: this.$t("success"),
            message: this.$t("data_changed"),
            type: "success",
          });
        })
        .catch(() => {
          this.loading_repair = false;
          this.$notify.error({
            title: this.$t("error"),
            message: this.$t("data_unchanged"),
          });
        });
    },
    async RepairDelete(id) {
      this.loading_repair = true;
      await axios
        .delete(`repairs/${id}/`)
        .then(() => {
          this.loading_repair = false;
          this.getRepairs();
          this.$notify({
            title: this.$t("success"),
            message: this.$t("data_deleted"),
            type: "success",
          });
        })
        .catch(() => {
          this.loading_repair = false;
          this.$notify.error({
            title: this.$t("error"),
            message: this.$t("data_not_deleted"),
          });
        });
    },
    async SpareDelete(id) {
      this.loading_repair = true;
      await axios
        .delete(`spares/${id}/`)
        .then(() => {
          this.loading_repair = false;
          this.getRepairs();
          this.$notify({
            title: this.$t("success"),
            message: this.$t("data_deleted"),
            type: "success",
          });
        })
        .catch(() => {
          this.loading_repair = false;
          this.$notify.error({
            title: this.$t("error"),
            message: this.$t("data_not_deleted"),
          });
        });
    },
    CloseRepair() {
      this.editR = false;
      Object.keys(this.repair).forEach((key) => {
        this.repair[key] = "";
      });
      this.$refs.repair.clearValidate();
    },
    CloseComment() {
      this.comment.text = "";
      this.edit_Comment = false;
      this.$refs.comment_text.clearValidate();
    },
    async SaveComment() {
      this.$refs.comment_text.validate((valid) => {
        if (valid) {
          this.loading_comment = true;
          axios
            .post(`comments/`, {
              inspection: this.inspection.id,
              user: localStorage.id,
              text: this.comment.text,
            })
            .then(() => {
              this.loading_comment = false;
              this.dialogComment = false;
              this.getComments();
              this.$notify({
                title: this.$t("success"),
                message: this.$t("data_added"),
                type: "success",
              });
            })
            .catch(() => {
              this.loading_comment = false;
              this.$notify.error({
                title: this.$t("error"),
                message: this.$t("no_data_added"),
              });
            });
        }
      });
    },
    openEditComment(id) {
      this.dialogComment = true;
      this.edit_Comment = true;
      this.edit_comment_id = id;
      this.comment.text = this.comments.find((data) => data.id === id).text;
    },
    editComment() {
      this.$refs.comment_text.validate((valid) => {
        if (valid) {
          this.loading_comment = true;
          axios
            .patch(`comments/${this.edit_comment_id}/`, {
              inspection: this.inspection.id,
              user: localStorage.id,
              text: this.comment.text,
            })
            .then(() => {
              this.dialogComment = false;
              this.getComments();
              this.loading_comment = false;
              this.$notify({
                title: this.$t("success"),
                message: this.$t("data_added"),
                type: "success",
              });
            })
            .catch(() => {
              this.loading_comment = false;
              this.$notify.error({
                title: this.$t("error"),
                message: this.$t("no_data_added"),
              });
            });
        }
      });
    },
    async CommentDelete(id) {
      this.loading_comment = true;
      await axios
        .delete(`comments/${id}/`)
        .then(() => {
          this.loading_comment = false;
          this.getComments();
          this.$notify({
            title: this.$t("success"),
            message: this.$t("data_deleted"),
            type: "success",
          });
        })
        .catch(() => {
          this.loading_comment = false;
          this.$notify.error({
            title: this.$t("error"),
            message: this.$t("data_not_deleted"),
          });
        });
    },
    openSpareEdit(repairId, spareId) {
      this.dialogSpare = true;
      this.editS = true;
      this.edit_spare_id = spareId;
      let repair = this.repairs_list.find((data) => data.id === repairId);
      this.edit_spare = repair.spares.find((data) => data.id === spareId);
      Object.keys(this.spare).forEach((key) => {
        if (typeof this.edit_spare[key] === "object") {
          this.spare[key] = this.edit_spare[key].id;
        } else {
          this.spare[key] = this.edit_spare[key];
        }
      });
    },
    EditSpare() {
      this.$refs.spare.validate((valid) => {
        if (valid) {
          this.loading_repair = true;
          axios
            .patch(`spares/${this.edit_spare_id}/`, this.spare)
            .then(() => {
              this.loading_repair = false;
              this.dialogSpare = false;
              this.getRepairs();
              this.$notify({
                title: this.$t("success"),
                message: this.$t("data_changed"),
                type: "success",
              });
            })
            .catch(() => {
              this.loading_repair = false;
              this.$notify.error({
                title: this.$t("error"),
                message: this.$t("data_unchanged"),
              });
            });
        }
      });
    },
    openSpareAdd(id) {
      this.dialogSpare = true;
      this.spare.repair = id;
    },
    SaveSpare() {
      this.$refs.spare.validate((valid) => {
        if (valid) {
          this.loading_repair = true;
          axios
            .post(`spares/`, this.spare)
            .then(() => {
              this.dialogSpare = false;
              this.loading_repair = false;
              this.getRepairs();
              this.$notify({
                title: this.$t("success"),
                message: this.$t("data_added"),
                type: "success",
              });
            })
            .catch(() => {
              this.loading_repair = false;
              this.$notify.error({
                title: this.$t("error"),
                message: this.$t("no_data_added"),
              });
            });
        }
      });
    },
    CloseSpare() {
      this.editS = false;
      Object.keys(this.spare).forEach((key) => {
        this.spare[key] = "";
      });
      this.$refs.spare.clearValidate();
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.spare_table {
  .el-table tr,
  .el-table th.el-table__cell {
    background: rgba(233, 239, 253, 0.4) !important;
  }
}
.pdf_table {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  border: 0.1px solid #000;
  th {
    font-weight: normal;
  }
  tr:not(:last-child) {
    overflow: hidden;
    border-bottom: 0.1px solid #000;
  }
}
</style>
